import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'url-search-params-polyfill';
import LogoutModal from '../LogoutModal';
import { executeLogout } from '@/utils/excuteLogout';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { FooterStorePolicies } from '../DesignSystem/Footers/FooterStorePolicies';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { useSSRSelector } from '@/redux/ssrStore';
import { getStorePolicies } from '@/redux/actions';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const FooterStoreInfo = ({ store, props }) => {
  const { user, storePolicies } = useSelector((state) => ({
    user: state.userReducer,
    storePolicies: state.storePolicyReducer.data,
  }));
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const { visitorLoginFeatureStatus } = useFeatureLocks();
  const isVisitorLoginVisible = visitorLoginFeatureStatus?.isVisible;
  const { storePhone } = useStoreContactDetails();

  useEffect(() => {
    if (storeData?.store_id && !storePolicies?.length)
      dispatch(getStorePolicies({ store_id: storeData.store_id }));
  }, [storeData?.store_id, storePolicies?.length]);

  useEffect(() => {
    setShowLogoutConfirmation(false);
  }, [user?.isLogin]);

  const toggleAuthModal = () => {
    if (user?.isLogin) {
      toggleLogoutModal();
    } else {
      router.push(getRoute(PATH.ORDERS, store?.store_info?.domain));
    }
  };
  function toggleLogoutModal() {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function renderLoginLogout() {
    return (
      <>
        <div className="footer1-login-logout" onClick={toggleAuthModal}>
          <div className="footer1-login-logout-button fw5 pointer">
            <span style={{ fontSize: 12 }}>{user?.isLogin ? 'Logout' : 'Login'}</span>
          </div>
        </div>
      </>
    );
  }

  function authAndLogoutModal() {
    return (
      <>
        {showLogoutConfirmation && (
          <LogoutModal
            toggleLogoutModal={toggleLogoutModal}
            executeLogout={executeLogout}
            showLogoutConfirmation={showLogoutConfirmation}
          />
        )}
      </>
    );
  }

  function renderSocialMediaProfileDetails() {
    if (
      !store.social_media_profile_details?.media_count ||
      store.social_media_profile_details?.media_count < 1
    )
      return null;
    return (
      <div className="footer-social-media-container" style={{ marginTop: '-10px' }}>
        <h6 style={{ marginBottom: '14px' }}>Follow us here</h6>
        <div className="social-media-section flex space-between" style={{ gap: '16px' }}>
          {store.social_media_profile_details?.media_detail.map((profile) => {
            return profile.profile_url !== '' ? (
              <div key={profile.id} className="social-media-icon">
                <a href={profile.profile_url} target="_blank" rel="noreferrer">
                  <img
                    src={profile.image_url}
                    alt={`${profile?.name} image`}
                    height={24}
                    width={24}
                  />
                </a>
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  }

  const onCallBtnClick = () => {
    window.open(`tel:${storePhone}`, '_self');
  };

  return (
    <>
      {authAndLogoutModal()}
      {store && (
        <div
          className="footerStoreInfo"
          style={{
            backgroundImage: `url(/assets/images/footerInfo-bgImg.png)`,
          }}
        >
          <div className="footerStoreInfo__name">{store?.store_info?.name}</div>
          <div>{isVisitorLoginVisible && renderLoginLogout()}</div>
          {store.address && store?.address?.visibility === 1 && (
            <address className="footerStoreInfo__address">
              <img alt="address" loading="lazy" src="/assets/images/3@2x.png" />
              <span>{store?.address.address_1}</span>
            </address>
          )}
          {!!storePhone && (
            <div className="footerStoreInfo__phone" onClick={onCallBtnClick}>
              <img alt="phone" loading="lazy" src="/assets/images/phone.png" />
              {storePhone}
            </div>
          )}
          {store?.social_media_profile_details && renderSocialMediaProfileDetails()}
          <FooterStorePolicies storePolicies={storePolicies} path={PATH.STORE_POLICIES} />
          {!props?.config?.hidden_components?.payment_method_images_not_visible && (
            <article
              style={{ display: 'none' }}
              className="footerStoreInfo_paymentOption"
            >
              <span>{getCustomCtaText('pay_after_cta')}</span>
              <img
                src="/assets/images/icons@2x.png"
                alt="payment options"
                loading="lazy"
              />
            </article>
          )}
        </div>
      )}
    </>
  );
};

export default FooterStoreInfo;
